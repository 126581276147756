<template>
  <base-zupu-details-page>
    <page-not-found slot="page-not-found"></page-not-found>
    <zupu-detail-page-header slot="header" slot-scope="props" :zupu="props.zupu"></zupu-detail-page-header>
    <translate-cta slot="info-cta"></translate-cta>
  </base-zupu-details-page>
</template>

<script>
import BaseZupuDetailsPage from '@common/pages/zupuDetails/BaseZupuDetailsPage';

import PageNotFound from '@/components/page.not.found';

import TranslateCta from './TranslateCta';
import ZupuDetailPageHeader from './ZupuDetailPageHeader';

export default {
  created() {
    this.$stripe.init();
  },
  components: {
    TranslateCta,
    ZupuDetailPageHeader,
    BaseZupuDetailsPage,
    PageNotFound,
  },
};
</script>

<style scoped lang="scss"></style>
