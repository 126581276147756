<template>
  <div class="my-zupus-empty-state">
    <p class="no-zupus-text">You haven't saved any zupus yet.</p>
    <div class="records-intro">
      <description-disclaimer></description-disclaimer>
      <img :src="$getAsset('/assets/services/zupus/dict-preview.png')" class="preview-img" width="800" height="450" />
      <mcr-button-router-link class="start-button" :to="toZupusPage"
        >Start Browsing our Collection</mcr-button-router-link
      >
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';

import DescriptionDisclaimer from '@/components/modules/myZupus/common/DescriptionDisclaimer';

export default {
  computed: {
    toZupusPage() {
      return {name: 'search-tool-zupu'};
    },
  },
  components: {DescriptionDisclaimer, McrButtonRouterLink},
};
</script>

<style lang="scss" scoped>
.no-zupus-text {
  margin: 25px 0;
  color: $supplemental-text-color;
}
.records-intro {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid $divider-line-color;

  .preview-img {
    margin: 24px 0;
  }

  .start-button {
    margin: 12px 0 0;
  }
}
</style>
