<template>
  <stand-out-block class="translate-cta">
    <zupu-description-helper></zupu-description-helper>

    <mcr-button-router-link :to="toRoute" rel="nofollow" class="action-button"
      >Explore Translation Options</mcr-button-router-link
    >
  </stand-out-block>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

import ZupuDescriptionHelper from '@/components/modules/zupuDetails/ZupuDescriptionHelper';

export default {
  computed: {
    toRoute() {
      return {name: 'zupu-translation-services', query: {from: this.$route.fullPath}};
    },
  },
  components: {StandOutBlock, McrButtonRouterLink, ZupuDescriptionHelper},
};
</script>

<style lang="scss" scoped>
.translate-cta {
  margin-top: 36px;
  .action-button {
    flex-grow: 0;
  }
}
</style>
