import { render, staticRenderFns } from "./ZupuDetailPageHeader.vue?vue&type=template&id=06205439&scoped=true&"
import script from "./ZupuDetailPageHeader.vue?vue&type=script&lang=js&"
export * from "./ZupuDetailPageHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06205439",
  null
  
)

export default component.exports