<template>
  <page-record-offline v-if="isRecordOffline"></page-record-offline>
  <div v-else-if="isRecordNotExist">
    <slot name="page-not-found"></slot>
  </div>
  <div class="search-tool-zupu-detail-page" v-else>
    <div class="limited_content">
      <mcr-loading-indicator
        v-if="clanPedigreeDetailsLoadingState || !clanPedigreeDetailsState"
        :loading="true"
      ></mcr-loading-indicator>
      <div class="zupu-detail-content" v-else>
        <slot name="header" :zupu="clanPedigreeDetailsState"></slot>
        <div class="zupu-preview">
          <physical-copy-logo
            v-if="clanPedigreeDetailsState.is_physical_only"
            :zupu="clanPedigreeDetailsState"
          ></physical-copy-logo>

          <card-with-ribbon
            v-else
            :class="{'is-placeholder': !clanPedigreeDetailsState.cover_id}"
            text="Look Inside!"
            @click.native="goToZupuViewer()"
          >
            <img :src="previewSrc" />
          </card-with-ribbon>

          <icon-input
            v-if="clanPedigreeDetailsState.has_images"
            type="search"
            placeholder="Search this document..."
            :ref="inputRef"
            @keyup.enter="search"
          >
            <search-icon class="search-icon" :size="28" />
          </icon-input>
        </div>
        <div class="zupu-info">
          <slot name="header" :zupu="clanPedigreeDetailsState"></slot>
          <div class="persons-indexed sub-section" v-if="clanPedigreeDetailsState.mentions_count">
            <b>Total persons indexed:</b><span> {{ clanPedigreeDetailsState.mentions_count }}</span>
          </div>

          <div v-if="clanPedigreeDetailsState.mentions_count" class="sub-section">
            <section-search-records
              :search-route="searchAllRoute"
              :init-last-name="clanName"
              @submit="searchMentions"
            ></section-search-records>
          </div>
          <div v-else-if="clanPedigreeDetailsState.has_ocr" class="sub-section">
            <section-search-ocr
              :search-route="searchAllRoute"
              :init-last-name="clanName"
              @submit="searchOcr"
            ></section-search-ocr>
          </div>

          <div class="zupu-detail-page-content">
            <summary-text
              v-if="clanPedigreeDetailsState.summary"
              :summary="clanPedigreeDetailsState.summary"
              :summary-preview="clanPedigreeDetailsState.summary_preview"
            ></summary-text>

            <zupu-details :zupu="clanPedigreeDetailsState"></zupu-details>

            <slot name="info-cta"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconInput from '@common/elements/inputs/IconInput';
import CardWithRibbon from '@common/elements/layouts/CardWithRibbon';
import PageRecordOffline from '@common/pages/pageRecordOffline';
import PhysicalCopyLogo from '@common/pages/zupuDetails/PhysicalCopyLogo';
import SectionSearchOcr from '@common/pages/zupuDetails/SectionSearchOcr';
import SectionSearchRecords from '@common/pages/zupuDetails/SectionSearchRecords';
import SummaryText from '@common/pages/zupuDetails/SummaryText';
import ZupuDetails from '@common/pages/zupuDetails/ZupuDetails';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getClanPedigreePreviewSrc} from '@common/utils/utils.clan-pedigree';
import SearchIcon from 'vue-material-design-icons/Magnify';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    const zupu = this.clanPedigreeDetailsState || {};
    let surnames = zupu.clans
      ? zupu.clans
          .map(clan => {
            let surname = `${clan.pinyin} ${clan.name_ch}`;
            return surname && clan.name_hant !== clan.name_ch ? `${surname} (${clan.name_hant})` : surname;
          })
          .join(', ')
      : '';
    const place =
      zupu.ancestral_places && zupu.ancestral_places.length
        ? zupu.ancestral_places.map(place => `${place.pinyin || ''} ${place.name || ''}`).join(', ')
        : 'Unknown Location';
    return {
      title: `${zupu.title_pinyin || ''} ${zupu.title_original || ''} – Family Tree Books`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Title: ${this.bookTitle}. Family Tree Book. ${surnames} family from ${place}. View or Save to My Zupus.`,
        },
      ],
    };
  },
  data() {
    return {
      inputRef: 'search-input',
      isRecordOffline: false,
      isRecordNotExist: false,
    };
  },
  computed: {
    ...mapGetters(['clanPedigreeDetailsState', 'clanPedigreeDetailsLoadingState']),
    zupuId() {
      return this.$route.params.zupuId;
    },
    sourceId() {
      return this.clanPedigreeDetailsState && this.clanPedigreeDetailsState.source_object_id;
    },
    previewSrc() {
      return getClanPedigreePreviewSrc(this.clanPedigreeDetailsState.cover_id, null, 512);
    },
    bookTitle() {
      if (!this.clanPedigreeDetailsState) {
        return '';
      }
      const title = `${this.clanPedigreeDetailsState.title_pinyin || ''} ${
        this.clanPedigreeDetailsState.title_original || ''
      }`;
      return this.clanPedigreeDetailsState.publication_year
        ? `${title}, ${this.clanPedigreeDetailsState.publication_year}`
        : title;
    },
    clanName() {
      if (this.clanPedigreeDetailsState && this.clanPedigreeDetailsState.clans.length) {
        const clan = this.clanPedigreeDetailsState.clans[0];
        return clan.name_hant || clan.name_ch;
      }
      return '';
    },
    searchAllRoute() {
      const query = {source_id: this.clanPedigreeDetailsState.source_object_id, tab: TAB_ID_INDEXED_RECORDS};
      return {name: 'search-tool-zupu', query: query};
    },
  },
  created() {
    if (!this.clanPedigreeDetailsState || this.clanPedigreeDetailsState.object_id !== this.$route.params.zupuId) {
      this.loadClanPedigree();
    }
  },
  methods: {
    loadClanPedigree() {
      this.$store.dispatch('getClanPedigreeDetailsAction', this.zupuId).catch(error => {
        if (error.response && error.response.data && error.response.data.is_offline) {
          this.isRecordOffline = true;
        } else {
          this.isRecordNotExist = true;
        }
      });
    },
    search() {
      this.goToZupuViewer(this.$refs[this.inputRef].$refs.input.value);
    },
    goToZupuViewer(search) {
      const config = search
        ? {name: 'source-viewer', params: {sourceId: this.sourceId}, query: {search}}
        : {name: 'source-viewer', params: {sourceId: this.sourceId}};
      this.$router.push(config);
    },
    searchMentions({firstName, lastName}) {
      const query = {
        source_id: this.clanPedigreeDetailsState.source_object_id,
        first_name: firstName,
        surname: lastName,
        tab: TAB_ID_INDEXED_RECORDS,
      };
      this.$router.push({name: 'search-tool-zupu', query: query});
    },
    searchOcr({firstName, lastName}) {
      const query = {
        source_id: this.clanPedigreeDetailsState.source_object_id,
        q: firstName,
        last_name: lastName,
        tab: TAB_ID_FULL_TEXT,
      };
      this.$router.push({name: 'search-tool-zupu', query: query});
    },
  },
  components: {
    PhysicalCopyLogo,
    SummaryText,
    ZupuDetails,
    CardWithRibbon,
    SearchIcon,
    IconInput,
    SectionSearchRecords,
    PageRecordOffline,
    SectionSearchOcr,
  },
  name: 'BaseZupuDetailsPage',
};
</script>

<style scoped lang="scss">
.search-tool-zupu-detail-page {
  .zupu-detail-content {
    display: flex;
    flex-direction: row;

    > .zupu-detail-page-header {
      display: none;
    }

    .zupu-preview {
      min-width: 40%;
      display: flex;
      flex-direction: column;

      .card-with-ribbon {
        align-self: center;
        cursor: pointer;
        min-width: 100%;
        box-shadow: $box-shadow;
        background: $background-second-alternate-color;

        &.is-placeholder {
          background: $mcr-red;

          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        img {
          width: 100%;
        }
      }

      .icon-input {
        margin-top: 12px;
        box-shadow: $box-shadow-light;
      }
    }

    .zupu-info {
      margin-left: 48px;
      flex-grow: 1;

      .zupu-detail-page-content {
        margin-top: 35px;

        .summary-text {
          margin-top: 40px;
        }
      }
    }

    .sub-section {
      display: block;
      margin-top: 18px;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;

      > .zupu-detail-page-header {
        display: block;
        border-bottom: 1px solid $divider-line-color;
        margin-bottom: 24px;
      }

      .zupu-preview {
        min-width: auto;
        .card-with-ribbon::v-deep {
          min-width: 200px;
          img {
            max-height: 400px;
          }
        }
        .icon-input {
          margin-top: 24px;
        }
      }

      .zupu-info {
        margin-left: 0;
        > .zupu-detail-page-header {
          display: none;
        }
      }
    }
  }
}
</style>
