<template>
  <div class="my-records">
    <div class="readable_content">
      <h4 v-if="!zupusLoading && noZupus">My Zupus</h4>

      <my-zupus-empty-state v-if="!zupusLoading && noZupus"></my-zupus-empty-state>
      <mcr-loading-indicator v-if="allZupusLoading" :loading="true"></mcr-loading-indicator>
      <template v-else>
        <h4 v-if="ownedClanPedigreeListState.length">My Zupus (Permanent Access)</h4>
        <mcr-loading-indicator v-if="ownedClanPedigreeListLoadingState" :loading="true"></mcr-loading-indicator>
        <div v-else-if="ownedClanPedigreeListState.length" class="zupu-list">
          <zupu-item
            v-for="ownedZupu in ownedClanPedigreeListState"
            :key="ownedZupu.clan_pedigree.object_id"
            :owned-zupu="ownedZupu"
            :removable="false"
          ></zupu-item>
          <div class="pagination-container">
            <base-pagination :meta="ownedClanPedigreeListMetaState" @onSwitchPage="onSwitchPageOwned"></base-pagination>
          </div>
        </div>

        <h4 v-if="savedClanPedigreeListState.length">Saved Zupus</h4>
        <mcr-loading-indicator v-if="savedClanPedigreeListLoadingState" :loading="true"></mcr-loading-indicator>
        <div v-else-if="savedClanPedigreeListState.length" class="zupu-list">
          <zupu-item
            v-for="ownedZupu in savedClanPedigreeListState"
            :key="ownedZupu.clan_pedigree.object_id"
            :owned-zupu="ownedZupu"
            :removable="true"
          ></zupu-item>
          <div class="pagination-container">
            <base-pagination :meta="savedClanPedigreeListMetaState" @onSwitchPage="onSwitchPageSaved"></base-pagination>
          </div>
        </div>

        <div class="my-records-footer">
          <frequently-asked-questions></frequently-asked-questions>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import {mapGetters} from 'vuex';

import MyZupusEmptyState from '@/components/modules/myZupus/MyZupusEmptyState';
import FrequentlyAskedQuestions from '@/components/modules/myZupus/common/FrequentlyAskedQuestions';

import ZupuItem from './ZupuItem';

export default {
  metaInfo: {
    title: 'My Zupus',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Preserve, organize and decode your family records from China thanks to our built-in Chinese-English dictionary.',
      },
    ],
  },
  data() {
    return {
      selectedZupu: {},
    };
  },
  created() {
    this.fetchOwnedZupus();
    this.fetchSavedZupus();
    this.$stripe.init();
  },
  computed: {
    ...mapGetters([
      'savedClanPedigreeListState',
      'savedClanPedigreeListMetaState',
      'savedClanPedigreeListLoadingState',
      'ownedClanPedigreeListState',
      'ownedClanPedigreeListMetaState',
      'ownedClanPedigreeListLoadingState',
    ]),
    toZupusPage() {
      return {name: 'search-tool-zupu'};
    },
    noZupus() {
      return !this.savedClanPedigreeListState.length && !this.ownedClanPedigreeListState.length;
    },
    zupusLoading() {
      return this.savedClanPedigreeListLoadingState || this.ownedClanPedigreeListLoadingState;
    },
    allZupusLoading() {
      return this.savedClanPedigreeListLoadingState && this.ownedClanPedigreeListLoadingState;
    },
  },
  methods: {
    fetchOwnedZupus(page) {
      const params = {page: page || 1};
      this.$store.dispatch('fetchOwnedClanPedigreesAction', params);
    },
    fetchSavedZupus(page) {
      const params = {page: page || 1};
      this.$store.dispatch('fetchSavedClanPedigreesAction', params);
    },
    onSwitchPageSaved(page) {
      this.fetchSavedZupus(page);
    },
    onSwitchPageOwned(page) {
      this.fetchOwnedZupus(page);
    },
  },
  components: {
    FrequentlyAskedQuestions,
    MyZupusEmptyState,
    ZupuItem,
    BasePagination,
  },
};
</script>

<style lang="scss" scoped>
.my-records {
  h4 {
    margin-bottom: 0;
  }

  .my-records-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .faq {
      margin-top: 24px;
      padding-top: 24px;
      width: 100%;
    }
  }
}
</style>
