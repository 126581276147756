<template>
  <div class="zupu-item">
    <div class="zupu-thumb">
      <router-link :to="zupuViewerRoute" :class="{'no-preview': imageError}">
        <img v-if="imageError" :src="$getAsset('/assets/mcr_logo_white_transparent.png')" />
        <img v-else :src="zupuPreview" @error="onImageLoadingError" />
      </router-link>
    </div>
    <div class="zupu-meta">
      <h5>
        <router-link :to="zupuViewerRoute">
          {{ clanPedigree.title_original }} {{ clanPedigree.title_pinyin }}
          <span v-if="clanPedigree.publication_year">({{ clanPedigree.publication_year }})</span>
        </router-link>
      </h5>
      <div class="meta-line">
        <link-surname-page-family :clans="ownedZupu.clan_pedigree.clans"></link-surname-page-family>
        <span v-if="ancestralPlaces && ancestralPlaces.length"> from </span>
        <link-place-page :place="place" v-for="place in ancestralPlaces" :key="place.id" class="name"></link-place-page>
      </div>

      <div class="own-info">
        <span class="supplemental-meta">
          <span class="purchase-date">Added on {{ createdAt }}</span>
          <span v-if="removable"> - <a class="remove-record-link" @click="openDeleteModal">Remove</a></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LinkPlacePage from '@common/elements/links/linkPlacePage';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {getClanPedigreePreviewSrc} from '@common/utils/utils.clan-pedigree';
import moment from 'moment';

import zupuDeleteConfirmModal from '@/components/modules/myZupus/modals/zupuDeleteConfirmModal';

export default {
  components: {LinkPlacePage, linkSurnamePageFamily},
  props: {
    ownedZupu: Object,
    removable: Boolean,
  },
  data() {
    return {
      imageError: !this.ownedZupu.clan_pedigree.cover_id,
    };
  },
  computed: {
    clanPedigree() {
      return this.ownedZupu.clan_pedigree;
    },
    ancestralPlaces() {
      return this.ownedZupu.clan_pedigree.ancestral_places;
    },
    createdAt() {
      return moment(this.ownedZupu.created_at).format('MMM DD YYYY');
    },
    zupuViewerRoute() {
      const sourceId = this.clanPedigree.source_id;
      return {name: 'source-viewer', params: {sourceId}, query: {from: this.$route.fullPath}};
    },
    zupuPreview() {
      return getClanPedigreePreviewSrc(this.clanPedigree.cover_id);
    },
  },
  methods: {
    onImageLoadingError() {
      this.imageError = true;
    },
    openDeleteModal() {
      let modalParams = {classes: 'clear_modal', name: 'zupu-item-delete-modal'};
      const props = {itemId: this.ownedZupu.object_id, zupuId: this.clanPedigree.object_id};
      this.$modal.show(zupuDeleteConfirmModal, props, modalParams);
    },
  },
  name: 'ZupuItem',
};
</script>

<style lang="scss" scoped>
.zupu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 0;
  .zupu-thumb {
    flex-shrink: 0;
    background-color: rgba(black, 0.05);
    margin-right: 24px;
    box-shadow: $box-shadow;
    width: 110px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &.no-preview {
        background-color: $link-color;
        img {
          width: 70%;
          height: auto;
        }
      }
    }
  }
  .zupu-meta {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    word-break: break-word;

    .own-info {
      display: flex;
      flex-direction: column;
      margin-top: 8px;

      span {
        margin-top: 6px;
      }
    }
    a {
      &:not(:hover) {
      }
    }
    h5 {
      font-family: $default-font;
      font-weight: normal;
      margin: 0;
      a {
        color: $text-color;
      }
    }
    .meta-line {
      margin-top: 5px;
      .name:not(:last-child)::after {
        content: ', ';
      }
    }
    .supplemental-meta {
      color: $supplemental-text-color;
      a {
        color: $supplemental-text-color;
      }
    }
  }
}
</style>
