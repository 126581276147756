<template>
  <div class="zupu-description-helper">
    <h5>A typical family history book includes...</h5>
    <div class="checkmark-list">
      <div class="checkmark-item">Family trees</div>
      <div class="checkmark-item">Names, dates, biographies</div>
      <div class="checkmark-item">Migration histories</div>
      <div class="checkmark-item">Famous ancestors</div>
      <div class="checkmark-item">Surname origins</div>
      <div class="checkmark-item">Generation poem or guidelines for naming newborns</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZupuDescriptionHelper',
  components: {},
};
</script>

<style lang="scss" scoped>
.zupu-description-helper {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  color: $neutral-500;

  h5 {
    font-size: $text-size;
  }
}
</style>
