<template>
  <div class="zupu-details">
    <div class="zupu-details-table">
      <details-item label="Surname">
        <link-surname-page-family :clans="zupu.clans" :show-family="false"></link-surname-page-family>
      </details-item>
      <details-item label="Ancestral Place" v-if="zupu.ancestral_places && zupu.ancestral_places.length">
        <link-place-page v-for="place in zupu.ancestral_places" :key="place.id" :place="place" class="name">{{
          place.pinyin
        }}</link-place-page>
      </details-item>
      <details-item label="Title"> {{ zupu.title_pinyin || '' }} {{ zupu.title_original || '' }} </details-item>
      <details-item v-if="zupu.provider" label="Library">
        <div v-if="zupu.provider && zupu.provider.parent && zupu.provider.name !== zupu.provider.parent.name">
          {{ zupu.provider.parent.name }}
        </div>
        <div v-else>{{ zupu.provider.name }}</div>
      </details-item>

      <details-item
        v-if="zupu.provider && zupu.provider.parent && zupu.provider.name !== zupu.provider.parent.name"
        label="Library Branch"
      >
        <div>{{ zupu.provider.name }} {{ zupu.provider.name_ch }}</div>
      </details-item>

      <details-item v-if="zupu.clan_hall_name" label="Clan Hall">
        <div>{{ zupu.clan_hall_name }}</div>
      </details-item>
      <details-item v-if="zupu.author" label="Author">
        <div>{{ zupu.author }}</div>
      </details-item>
      <details-item label="Publication Place">
        <router-link
          v-if="zupu.publication_place && zupu.publication_place.is_migration_destination"
          :to="migrationDestinationRoute"
          >{{ zupu.publication_place.pinyin }}</router-link
        >
        <span v-if="zupu.publication_place && !zupu.publication_place.is_migration_destination">
          {{ zupu.publication_place.pinyin }}
        </span>
      </details-item>
      <details-item label="Date Published">
        <div v-if="zupu.publication_year">
          {{ zupu.publication_year
          }}<span v-if="zupu.publication_year_latest">- {{ zupu.publication_year_latest }}</span>
        </div>
      </details-item>
      <details-item v-if="zupu.call_number" label="Call Number">
        <div>{{ zupu.call_number }}</div>
      </details-item>
      <details-item v-if="zupu.has_images" label="Pages">
        {{ zupu.images_count }}
      </details-item>
      <details-item label="Source" v-if="userIsStaffState">
        <div v-if="zupu.source">{{ zupu.source }}</div>
      </details-item>
    </div>
  </div>
</template>

<script>
import detailsItem from '@common/elements/layouts/detailsItem';
import LinkPlacePage from '@common/elements/links/linkPlacePage';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {getMigrationPlaceDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

export default {
  props: {
    zupu: Object,
  },
  computed: {
    ...mapGetters(['userIsStaffState']),
    migrationDestinationRoute() {
      if (!this.zupu.publication_place) {
        return {};
      }
      return getMigrationPlaceDetailRoute(this.zupu.publication_place.id, this.zupu.publication_place.pinyin);
    },
  },
  components: {detailsItem, LinkPlacePage, linkSurnamePageFamily},
  name: 'ZupuDetails',
};
</script>

<style scoped lang="scss">
.zupu-details {
  .name:not(:last-child)::after {
    content: ', ';
  }
}
</style>
