<template>
  <div class="zupu-detail-page-header">
    <h5 v-if="zupu.is_physical_only">{{ title }}</h5>
    <router-link v-else :to="zupuViewerRoute"
      ><h5>{{ title }}</h5></router-link
    >
    <div class="zupu-top-meta">
      <record-type>
        <span>Zupu, </span>
        <link-surname-page-family :clans="zupu.clans"></link-surname-page-family>
        <span v-if="zupu.ancestral_places && zupu.ancestral_places.length">
          from
          <link-place-page
            v-for="place in zupu.ancestral_places"
            :key="place.id"
            :place="place"
            class="name"
          ></link-place-page
        ></span>
      </record-type>
    </div>

    <div class="zupu-actions" :class="{'as-column': zupuActionsAsColumn}">
      <mcr-button-router-link-to-pr
        v-if="zupu.is_physical_only && !userIsSharedState"
        label="Hire a Researcher to Visit Library"
        @click="prefillContactForm"
      ></mcr-button-router-link-to-pr>
      <mcr-button
        v-if="!zupu.is_physical_only"
        class="view-owned-zupu-button"
        label="View Zupu"
        @click="goToZupuViewer"
      ></mcr-button>
      <slot name="header-buttons"></slot>
    </div>
    <div class="admin-controls" v-if="userIsStaffState">
      <mcr-button :href="adminEditZupuLink" target="_blank" class="admin transparent">Open in admin</mcr-button>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import RecordType from '@common/elements/icons/RecordType';
import LinkPlacePage from '@common/elements/links/linkPlacePage';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {getClanPedigreeAdminUrl} from '@common/utils/utils.admin';
import {mapGetters} from 'vuex';

export default {
  props: {
    zupu: Object,
  },
  computed: {
    ...mapGetters(['userIsStaffState', 'userIsLoggedInState', 'userIsSharedState', 'windowWidthState']),
    title() {
      const title = `${this.zupu.title_pinyin || ''} ${this.zupu.title_original || ''}`;
      return this.zupu.publication_year ? `${title}, ${this.zupu.publication_year}` : title;
    },
    zupuViewerRoute() {
      return {name: 'source-viewer', params: {sourceId: this.zupu.source_object_id}};
    },
    zupuActionsAsColumn() {
      return this.windowWidthState <= this.$breakpoints.tablet;
    },
    contactFormComment() {
      const zupuName = this.title;
      const libraryName = this.zupu.provider ? this.zupu.provider.name : 'site';
      const url = window.location;

      return `I'm interested in researching the ${zupuName} at ${libraryName}: ${url}`;
    },
    adminEditZupuLink() {
      return getClanPedigreeAdminUrl(this.zupu.object_id);
    },
  },
  methods: {
    goToZupuViewer() {
      this.$router.push(this.zupuViewerRoute);
    },
    prefillContactForm() {
      this.$store.commit('setContactUsFormInitDataState', {
        otherComment: this.contactFormComment,
      });
    },
  },
  components: {
    McrButtonRouterLinkToPr,
    linkSurnamePageFamily,
    LinkPlacePage,
    mcrButton,
    RecordType,
  },
  name: 'BaseZupuDetailPageHeader',
};
</script>

<style lang="scss" scoped>
.zupu-detail-page-header {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  h5 {
    margin: 0;
  }

  .zupu-top-meta {
    border-bottom: 1px solid $divider-line-color;
    padding: 8px 0 16px;

    .record-type {
      display: inline;

      &::v-deep .material-design-icon {
        vertical-align: top;
      }
    }

    &::v-deep .name:not(:last-child)::after {
      content: ', ';
    }
  }

  .view-owned-zupu-button {
    max-width: 200px;
    margin-right: 22px;
  }

  .preview-zupu-link {
    .inside-icon {
      display: inline-block;
      transform: scaleX(-1);
      position: relative;
      margin-left: 5px;
      opacity: 0.82;
      margin-top: 7px;
      text-align: center;
    }

    display: flex;
    align-items: center;
    margin-top: 15px;
    color: $supplemental-text-color;
  }

  .zupu-actions {
    margin: 24px 0;
    display: flex;
    align-items: center;

    .zupu-access-controls {
      display: flex;
      align-items: center;
    }

    &::v-deep {
      .mcr-button {
        min-width: 150px;
        margin-right: 16px;
      }
    }

    &.as-column {
      flex-direction: column;
      width: 100%;
      .zupu-access-controls,
      &::v-deep .get-access-buttons {
        flex-direction: column;
        width: 100%;
      }
      &::v-deep .mcr-button {
        margin-right: 0;
        margin-bottom: 22px;
        max-width: unset;
        width: 100%;
      }
    }
  }

  .admin-controls {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}
</style>
