<template>
  <base-zupu-detail-page-header :zupu="zupu">
    <template slot="header-buttons">
      <save-zupu-button :is-saved="zupu.is_user_saved" :zupu-id="zupu.object_id"></save-zupu-button>
    </template>
  </base-zupu-detail-page-header>
</template>

<script>
import BaseZupuDetailPageHeader from '@common/pages/zupuDetails/BaseZupuDetailPageHeader';

import SaveZupuButton from '@/components/common/buttons/SaveZupuButton';

export default {
  props: {
    zupu: Object,
  },
  components: {
    BaseZupuDetailPageHeader,
    SaveZupuButton,
  },
  name: 'ZupuDetailPageHeader',
};
</script>

<style lang="scss" scoped></style>
